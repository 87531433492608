<template>
 <div  class=" offset pt-4 pb-16"> 
      <Navbar path="/"/>
         <p class="title-2  center-text"> FAQS</p>
         <p class="text-question">¿Qué es Yipi?</p>
        <p class="paragraph-1">Yipi es un servicio de transporte para las cortas distancias dentro de zonas definidas, mediante
                 una app o tarjeta de prepago, y a través de miniautos eléctricos.
        </p>

        <p class="text-question">¿Cómo me afilio a Yipi?</p>
        <p class="paragraph-1">¡¡Facilísimo!! Debes acudir a nuestro punto de afiliación y proporcionar los siguientes datos: nombre,
             fecha de nacimiento, teléfono y/o correo. Te llegará un mensaje de confirmación a tu celular que nos deberás proporcionar para darte de alta como parte del club Yipi.
        </p>

        <p class="text-question">¿Dónde me puedo afiliar?</p>
        <p class="paragraph-1">En cada una de nuestras Células Yipi encontrarás un módulo de afiliación y recarga,
             por el momento el punto activo está en: Paseo Solares 156, 
            Fraccionamiento Solares Zapopan, Jalisco.
        </p>

        <p class="text-question">¿Cómo puedo subirme y viajar?</p>
        <p class="paragraph-1">APP- Tarjeta de prepago.
Por medio de la app: <br/>
Descarga la aplicación de forma gratuita desde la tienda de Android o IOS.<br/>
Crea tu perfil de usuario. <br/>
Recarga saldo a tu monedero (cuenta) Solicita tu Yipi, al finalizar tu viaje se hará el cobro en automático.<br/>
Podrás calificar el servicio y al conductor.<br/>
<br/>
Por medio de la tarjeta de prepago:<br/>
Acude a nuestro centro de afiliación o puntos de convenio.<br/>
Afíliate con nosotros (nombre, fecha de nacimiento, teléfono).<br/>
<br/>
Recibirás un mensaje con tu código de confirmación que nos deberás proporcionar para concluir tu registro.<br/>
Recarga saldo a tu tarjeta.<br/>
¡Ahora podrás viajar en Yipi!, dentro del polígono de servicio.

        </p>

        <p class="text-question">¿Cuánto cuestan la experiencia?</p>
        <p class="paragraph-1">
            Por app:<br/>
En función de la distancia recorrida y/o tiempo transcurrido.<br/>
<br/>

            Por tarjeta:<br/>
Viaje Personal: distancia recorrida.<br/>
Viaje Compartido: cuota establecida para el polígono de servicio en que te moverás.

        </p>

        <p class="text-question">¿Cómo recargo mi tarjeta?</p>
        <p class="paragraph-1">
            En nuestros centros de afiliación, ahora punto Solares próximamente en nuestra web.
        </p>

        <p class="text-question">¿Contacto?</p>
        <p class="paragraph-1">
         Atención a clientes   <a href="tel:+52 3324108448" style="color: #00E882;"> 3324108448</a>. 
        </p>
                
    </div>
</template>
<script>
import Navbar from "../components/commons/Navbar.vue";
export default {
    components:{
        Navbar
    }
}
</script>
<style scoped>
.center-text{
    text-align: center;
}
    .text-question{
        font-family: Open Sans;
        font-size: 2em;
        color: #00E882;
        font-weight: 800;
    }
</style>